/* jshint esversion: 6 */
/* GLOBALS: $ */

import Xiao from "xiao-router";

export const trivial = (function(){

  const initialPage = 'home';
  const pageClassPrefix = 'is-page-';
  const $body = $('body');
  const numberWords = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'];
  const possibleChoices = ['option A', 'option B', 'option C', 'option D', 'option E', 'option F', 'option G'];
  let customChoices = false;
  let choiceCount = 0;
  let initialCount = 0;
  let choiceList = [];
  let choice = false;
  let choiceIndex = 0;
  let first = true;
  let iFeel = false;

  const routes = [
    {
      id: 'home',
      label: 'Welcome'
    },
    {
      id: 'what'
    },
    {
      id: 'unstick',
      label: 'Let\'s make a decision',
      arrived: unstickReset
    },
    {
      id: 'option-names',
      arrived: namesReset
    },
    {
      id: 'decision-touch',
      label: 'how about...',
      arrived: decisionShuffle
    },
    {
      id: 'wanna-name',
      label: 'Want to name your options?',
      arrived: wannaNameReset
    },
    {
      id: 'great',
      arrived: great
    },
    {
      id: 'fine',
      arrived: fine
    },
    {
      id: 'remaining',
      arrived: remaining
    }
  ];

  const app = new Xiao(routes, initialPage, {
    arrived: arrive,
    departed: depart
  });

  function arrive(elem) {
    $body.classList.add(pageClassPrefix + elem.id);
  }

  function depart(elem) {
    $body.classList.remove(pageClassPrefix + elem.id);
  }

  function init () {
    $('#unstick-choices')._.delegate('click', 'input', function(e){
      $('#unstick-next').removeAttribute('disabled', '');
      newChoiceList(e.target.value);
    });
    $('#unstick-next')._.bind('click', function(){
      app.reroute('wanna-name');
    });

    $('#decision-choices')._.delegate('click', 'input', function(e){
      $('#decision-touch-next').removeAttribute('disabled', '');
      iFeel = e.target.value;
    });
    $('#decision-touch-next')._.bind('click', function(e){
      howDoYouFeel(e);
    });

    $('#names-next')._.bind('click', function(){
      app.reroute('decision-touch');
    });

    for (let i = 1; i <= 7; i++) {
      $(`#option-${i}-name`)._.bind('keyup', nameTrack);
    }
  }

  function newChoiceList(count) {
    count = parseInt(count);
    choiceCount = count;
    initialCount = count;
    choiceList = [];
    first = true;
    let i;
    for (i=0;i<count;i++) {
      choiceList.push(possibleChoices[i]);
    }
    $('#how-many').innerHTML = numberWords[count - 1];
    primeNames(count);
  }

  function unstickReset() {
    choiceCount = 0;
    choiceList = [];
    choice = false;
    initialCount = 0;

    $$('#unstick-choices input').forEach(function($e){
      $e.checked = false;
    });

    $('#unstick-next').setAttribute('disabled', '');
  }

  function wannaNameReset () {
    if (choiceCount === 0) {
      app.reroute('unstick');
    } else {
      customChoices = false;
    }
  }

  function namesReset () {
    if (choiceCount === 0) {
      app.reroute('unstick');
    } else {
      $('#names-next').setAttribute('disabled', '');
    }
  }

  function primeNames (count) {
    for (let i = 1; i <= 7; i++) {
      if (i <= count) {
        $(`#option-${i}-row`).removeAttribute('hidden');
      } else {
        $(`#option-${i}-row`).setAttribute('hidden', '');
      }
      $(`#option-${i}-name`).value = '';
    }
  }

  function sanitarize(string) {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;'
    };
    const reg = /[&<>"'/]/ig;
    return string.replace(reg, (match)=>(map[match]));
  }

  function nameTrack () {
    let valid = true;
    for (let i = 1; i <= initialCount; i++) {
      if (!$(`#option-${i}-name`).value) {
        valid = false;
      }
    }

    if (valid) {
      customChoices = [];
      for (let i = 1; i <= initialCount; i++) {
        customChoices[i - 1] = sanitarize($(`#option-${i}-name`).value);
      }
      choiceList = customChoices;
      $('#names-next').removeAttribute('disabled');
    }
  }

  function decisionShuffle() {
    $$('#decision-choices input').forEach(function($e){
      $e.checked = false;
    });
    $('#decision-touch-next').setAttribute('disabled','');

    if (choiceCount === 0) {
      app.reroute('unstick');
    } else if (choiceList.length === 1) {
      choice = choiceList[0];
      app.reroute('remaining');
    } else {
      choiceIndex = Math.floor(Math.random() * choiceList.length);
      choice = choiceList[choiceIndex];
      if (first) {
        $('#you-should').innerHTML = `<p>I reckon you should go with <strong>${choice}</strong>.</p>`;
        first = false;
      } else {
        $('#you-should').innerHTML = `<p>How about <strong>${choice}?</strong></p>`;
      }
    }
    iFeel = false;
  }

  function howDoYouFeel(e) {
    if (iFeel === 'yes') {
      app.reroute('great');
    } else if (iFeel === 'hmm') {
      app.reroute('fine');
    } else if (iFeel === 'no') {
      choiceList.splice(choiceIndex, 1);
      if (choiceList.length === 1) {
        choice = choiceList[0];
        if (initialCount === 2) {
          app.reroute('great');
        } else {
          app.reroute('remaining');
        }
      } else {
        e.target.blur();
        document.getElementById('decision-touch').focus();
        decisionShuffle();
      }
    } else {
      app.reroute('unstick');
    }
  }

  function fine() {
    if (!choice) {
      app.reroute('unstick');
    } else {
      $('#you-should-fine').innerHTML = `Go ahead with <strong>${choice}</strong>, so you can get on with the rest of your day.`;
    }
  }

  function great() {
    if (!choice) {
      app.reroute('unstick');
    } else {
      $('#you-should-great').innerHTML = `Alright, <strong>${choice}</strong> it is then.`;
    }
  }

  function remaining () {
    if (!choice) {
      app.reroute('unstick');
    } else {
      $('#you-should-remaining').innerHTML = `That just leaves <strong>${choice}</strong>. You can do that!`;
    }
  }

  init();
})();
